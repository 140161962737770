/** @format */

p {
  font-size: medium;
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif;
  line-height: 2;
}

span {
  line-height: 2.4;
}
