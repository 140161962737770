@banner0: banner0;
.@{banner0} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  & &-text-wrapper {
    display: inline-block;
    position: absolute;
    top: 20%;
    margin: auto;
    left: 0;
    right: 0;
    font-size: 14px;
    color: @template-text-color-light;
    width: 550px;
    > .queue-anim-leaving {
      position: relative !important;
    }
  }
  & &-title {
    width: 550px;
    left: 30px;
    min-height: 60px;
    margin: auto;
    display: inline-block;
    font-size: 60px;
    position: relative;
  }
  & &-content {
    margin-bottom: 20px;
    word-wrap: break-word;
    min-height: 24px;
  }
  & &-button {
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
    box-shadow: 0 0 0 transparent;
    font-size: 16px;
    height: 40px;
    transition: background 0.45s @ease-out, box-shadow 0.45s @ease-out;
    &:hover {
      color: #fff;
      border-color: #fff;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
    }
    &:focus {
      color: #fff;
      border-color: #fff;
    }
    &.queue-anim-leaving {
      width: auto;
    }
  }
  & &-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    color: @template-text-color-light;
  }
}

@media screen and (max-width: 767px) {
  .@{banner0} {
    background-attachment: inherit;
    & &-text-wrapper {
      width: 90%;
    }
    & &-title {
      width: 90%;
      left: 0;
    }
  }
}
