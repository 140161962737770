/** @format */

* @format .header {
  font-family: "Merriweather", serif "ZCOOL XiaoWei", serif;
}

.category {
  font-family: "Libre Franklin", sans-serif;
}

.drawer {
  width: 240px;
}

.main {
  margin-left: 300px;
  margin-top: 10px;
  text-align: center;
  margin-right: 20px;
}

p {
  font-size: 15px;
  font-family: "Noto Sans SC", sans-serif;
}

blockquote {
  font-size: 1em;
  width: 100%;
  margin: 50px auto;
  font-style: italic;
  color: #555555;
  padding: 10px 10px 10px 40px;
  border-left: 8px solid rgb(255, 166, 0);
  line-height: 1.6;
  position: relative;
  background: #ededed;
}

blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: rgb(255, 136, 0);
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

blockquote::after {
  content: "";
}

blockquote span {
  display: block;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  font-size: medium;
  margin-top: 1em;
}

li span {
  font-size: medium;
}

pre {
  background-color: #ececec;
}

#post-title {
  background-color: #333333;
}

#post-detail {
  background-color: antiquewhite;
  display: block;
  font-size: 20px;
}

#post-time {
  background-color: antiquewhite;
}

.text {
  font-size: medium;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
